import type {
	IApiPaginatedFilterQuery,
	IApiResponse,
	IApiResponsePaginated,
	IWithdrawalDetailsQueryResp,
	IWithdrawalsQueryResp,
} from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const withdrawalsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getWithdrawals: builder.query<IApiResponsePaginated<IWithdrawalsQueryResp[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({
				url: '/admin/payments/withdrawals',
				params,
			}),
			providesTags: ['GetAllWithdrawals'],
		}),
		getWithdrawalDetails: builder.query<IApiResponse<IWithdrawalDetailsQueryResp>, string | undefined>({
			query: (txReference) => ({
				url: `/admin/payments/withdrawals/withdrawal/${txReference}`,
			}),
			providesTags: ['GetSingleWithdrawal'],
		}),
		approveOrRejectWithdrawal: builder.mutation<
			IApiResponse<IWithdrawalDetailsQueryResp>,
			Record<string, string | boolean | string[] | undefined>
		>({
			query: (body) => ({
				method: 'POST',
				url: '/admin/payments/withdrawals',
				body,
			}),
			invalidatesTags: ['GetAllWithdrawals', 'GetSingleWithdrawal', 'DashboardStats'],
		}),
	}),
});

export const { useGetWithdrawalsQuery, useGetWithdrawalDetailsQuery, useApproveOrRejectWithdrawalMutation } = withdrawalsApiSlice;
