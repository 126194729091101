import type {
	IApiPaginatedFilterQuery,
	IApiResponse,
	IApiResponsePaginated,
	IInvoiceAsset,
	IInvoiceTx,
	ISellAssetType,
	ISellTradeQueryResp,
} from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const tradesApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSellTrades: builder.query<IApiResponsePaginated<ISellTradeQueryResp[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({
				url: '/admin/trades/sells',
				params,
			}),
			providesTags: ['GetSellTrades'],
		}),
		getSingleSellTrade: builder.query<IApiResponsePaginated<ISellTradeQueryResp>, string | undefined>({
			query: (txReference) => ({
				url: `/admin/trades/sells/sell/${txReference}`,
			}),
			providesTags: ['GetSingleSellTrade'],
		}),
		getAllSellAssets: builder.query<IApiResponse<ISellAssetType[]>, { isActive: boolean } | void>({
			query: (params) => ({
				url: '/admin/trades/sells/assets',
				params: params || {},
			}),
		}),
		resolveSellTrade: builder.mutation<IApiResponse, Partial<{ txReference: string; completed: boolean }>>({
			query: ({ completed, txReference }) => ({
				method: 'POST',
				url: `/admin/trades/sells/sell/${txReference}/resolve`,
				body: { completed },
			}),
			invalidatesTags: ['GetSellTrades', 'GetSingleSellTrade'],
		}),
		getInvoices: builder.query<IApiResponsePaginated<IInvoiceTx[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({
				url: '/admin/trades/invoices',
				params,
			}),
			providesTags: ['GetInvoices'],
		}),
		getSingleInvoice: builder.query<IApiResponsePaginated<IInvoiceTx>, string | undefined>({
			query: (txReference) => ({
				url: `/admin/trades/invoices/invoice/${txReference}`,
			}),
			providesTags: ['GetSingleInvoice'],
		}),
		completeSingleInvoice: builder.mutation<IApiResponsePaginated, Record<string, boolean | string | undefined>>({
			query: ({ txReference, regenerate }) => ({
				method: 'PUT',
				url: `/admin/trades/invoices/invoice/${txReference}`,
				body: { regenerate },
			}),
			invalidatesTags: ['GetInvoices', 'GetSingleInvoice', 'DashboardStats'],
		}),
		deleteSingleInvoice: builder.mutation<IApiResponsePaginated, string>({
			query: (txReference) => ({
				method: 'PATCH',
				url: `/admin/trades/invoices/invoice/${txReference}`,
			}),
			invalidatesTags: ['GetInvoices', 'GetSingleInvoice', 'DashboardStats'],
		}),
		getAllInvoiceAssets: builder.query<IApiResponse<IInvoiceAsset[]>, void>({
			query: () => ({
				url: '/admin/trades/invoices/assets',
			}),
		}),
	}),
});

export const {
	useGetSellTradesQuery,
	useGetSingleSellTradeQuery,
	useGetAllSellAssetsQuery,
	useResolveSellTradeMutation,
	useGetInvoicesQuery,
	useGetSingleInvoiceQuery,
	useCompleteSingleInvoiceMutation,
	useDeleteSingleInvoiceMutation,
	useGetAllInvoiceAssetsQuery,
} = tradesApiSlice;
