import type { IApiPaginatedFilterQuery, IApiResponsePaginated, IBillTx } from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const billsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getBills: builder.query<IApiResponsePaginated<IBillTx[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({
				url: '/admin/bills',
				params,
			}),
		}),
		getSingleBill: builder.query<IApiResponsePaginated<IBillTx>, string | undefined>({
			query: (txReference) => ({
				url: `/admin/bills/bill/${txReference}`,
			}),
		}),
	}),
});

export const { useGetBillsQuery, useGetSingleBillQuery } = billsApiSlice;
