import type { RoutParamsType } from 'BreetConfig';
import { sanitizeRouteParams, userMgtSubRouteList } from 'BreetConfig';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import UserDetailsAction from '@/features/user-management/users/[userId]/userActions/UserDetailsAction';

import { SideNavBar } from './SideNavBar';

export const UserMgtUsersLayout = ({ children }: React.PropsWithChildren) => {
	const params = useParams<RoutParamsType>();
	const location = useLocation();

	const subRouteList = userMgtSubRouteList.map((routeItem) => sanitizeRouteParams(routeItem.linkRef, params));
	const isSubRoute = subRouteList.includes(location.pathname);

	const wrapperClassName = useMemo(() => (isSubRoute ? ' isSubRoute' : ''), [isSubRoute]);

	return (
		<div className={`userMgtUsersLayout${wrapperClassName}`}>
			{isSubRoute ? <SideNavBar /> : null}
			<div className='userMgtUsersLayout_view'>
				{isSubRoute ? <UserDetailsAction /> : null}
				{children}
			</div>
		</div>
	);
};
