import { Text } from 'BreetComponents';
import type { RoutParamsType } from 'BreetConfig';
import { sanitizeRouteParams, userMgtSubRouteList } from 'BreetConfig';
import { Link, useLocation, useParams } from 'react-router-dom';

export const SideNavBar = () => {
	const { pathname } = useLocation();
	const params = useParams<RoutParamsType>();

	const activeNavClass = (linkIncluded: boolean) => (linkIncluded ? ' active' : '');

	return (
		<div className='userMgtSubRouteList'>
			{userMgtSubRouteList.map((routeItem) => (
				<Link
					key={routeItem.linkRef}
					to={sanitizeRouteParams(routeItem.linkRef, params)}
					className={`userMgtSubRouteList_item${activeNavClass(sanitizeRouteParams(routeItem.linkRef, params).includes(pathname))}`}
				>
					<Text variant='body_small_bold'>{routeItem.linkTitle}</Text>
				</Link>
			))}
		</div>
	);
};
